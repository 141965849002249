import React from "react"
import { Link, graphql} from "gatsby"
import { AiOutlineHome  } from "react-icons/ai";
import SEO from "../components/seo"
import Layout from "../components/layout"
import Img from "gatsby-image"

export const query = graphql`
  query {
    gotMessage: file(relativePath: { eq: "got_message.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
  
const Thanks = ({ data }) => {
  return (
    <Layout className="thanks-page">
      <SEO title="Thank you" />
      <div
        className="wrapper"
        style={{
          textAlign: "center",
        }}
      >
        <Img
          fluid={data.gotMessage.childImageSharp.fluid}
          alt="got message"
          objectFit="cover"
          objectPosition="50% 50%"
        />
        <h1>Got your message</h1>
        <p>
          Thank you for getting in touch with us. We will get back to you
          shortly.
        </p>
        <Link to="/" className="button-block">
          <AiOutlineHome className="icon -left" />
          Go back to Home
        </Link>
      </div>
    </Layout>
  )
}

export default Thanks
